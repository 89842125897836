import React from 'react';
import { liteClient } from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, ClearRefinements, useInstantSearch } from 'react-instantsearch';
import SupplierCard from './SupplierCard';
import { MenuSelect } from './MenuSelect';
function HitsContainer({ children }) {
    return React.createElement("div", { className: "flex flex-col gap-6 justify-center items-center" }, children);
}
function Hit({ hit }) {
    return (React.createElement(SupplierCard, { hit: hit, logoUrl: hit.logo_url, name: hit.name, industry: hit.industry_1, description: hit.description, packaging: hit.packaging, country: hit.country, businessCategories: hit.business_categories, certifications: hit.certifications }));
}
function EmptyQueryBoundary({ children, fallback }) {
    const { indexUiState } = useInstantSearch();
    if (!indexUiState.query) {
        return (React.createElement(React.Fragment, null, fallback));
    }
    return children;
}
export function App() {
    const appId = process.env.ALGOLIA_APPLICATION_ID || '';
    const searchKey = process.env.ALGOLIA_API_KEY || '';
    const insights = process.env.ALGOLIA_INSIGHTS === 'true';
    const searchClient = liteClient(appId, searchKey);
    return (React.createElement(InstantSearch, { searchClient: searchClient, indexName: "public_idx", insights: insights, future: {
            preserveSharedStateOnUnmount: true,
        } },
        React.createElement(MenuSelect, { attribute: "country" }),
        React.createElement(MenuSelect, { attribute: "certifications" }),
        React.createElement(MenuSelect, { attribute: "business_categories" }),
        React.createElement(MenuSelect, { attribute: "packaging" }),
        React.createElement(ClearRefinements, { translations: { resetButtonText: "Clear all" } }),
        React.createElement(SearchBox, { placeholder: 'Search for suppliers', autoFocus: true }),
        React.createElement(EmptyQueryBoundary, { fallback: null },
            React.createElement(HitsContainer, null,
                React.createElement(Hits, { hitComponent: Hit })))));
}
