import React from 'react';
import { useMenu } from 'react-instantsearch';
const Arrow = () => (React.createElement("svg", { className: "absolute right-2 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400 pointer-events-none", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", fill: "currentColor" },
    React.createElement("path", { fillRule: "evenodd", d: "M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z", clipRule: "evenodd" })));
export function MenuSelect(props) {
    const { items, refine } = useMenu(props);
    const { value: selectedValue } = items.find((item) => item.isRefined) || {
        value: '',
    };
    const placeholderMap = {
        country: 'Country',
        certifications: 'Certificates',
        business_categories: 'Business Tags',
        packaging: 'Packaging',
    };
    return (React.createElement("div", { className: "relative inline-block" },
        React.createElement("select", { value: selectedValue || 'placeholder', onChange: (event) => {
                refine(event.target.value);
            }, className: "appearance-none bg-transparent text-gray-500 text-lg px-4 pr-8 py-1 border-b-2 border-gray-300 focus:outline-none focus:border-gray-500" },
            React.createElement("option", { value: "placeholder", disabled: true }, placeholderMap[props.attribute]),
            items.map((item) => (React.createElement("option", { key: item.value, value: item.value }, item.label)))),
        React.createElement(Arrow, null)));
}
