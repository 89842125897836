import React from "react";
import { Highlight } from 'react-instantsearch';
const SupplierCard = ({ hit, name, description, industry, country, certifications, businessCategories, packaging, logoUrl, }) => {
    const isValidImageUrl = (url) => {
        return /\.(jpg|jpeg|png|svg|gif)$/.test(url);
    };
    return (React.createElement("div", { className: "flex items-start p-6 bg-white shadow-md rounded-lg space-x-6" },
        logoUrl && isValidImageUrl(logoUrl) && (React.createElement("img", { src: logoUrl, alt: name || "Logo", className: "w-24 h-24 object-cover" })),
        React.createElement("div", { className: "flex-grow" },
            name && (React.createElement("h3", { className: "text-2xl font-bold mb-2" },
                React.createElement(Highlight, { attribute: "name", hit: hit }))),
            industry && industry.length > 0 && (React.createElement("p", { className: "text-sm text-gray-600 mb-2" },
                React.createElement("span", { className: "font-semibold" }, "Industry:"),
                " ",
                industry)),
            description && (React.createElement("p", { className: "text-sm text-gray-700 mb-4" },
                React.createElement(Highlight, { attribute: "description", hit: hit }))),
            packaging && packaging.length > 0 && (React.createElement("p", { className: "text-sm text-gray-600 mb-2" },
                React.createElement("span", { className: "font-semibold" }, "Packaging:"),
                " ",
                packaging)),
            country && (React.createElement("p", { className: "text-sm text-gray-600 mb-2" },
                React.createElement("span", { className: "font-semibold" }, "Country:"),
                " ",
                country)),
            businessCategories && businessCategories.length > 0 && (React.createElement("div", { className: "mb-4" },
                React.createElement("span", { className: "font-semibold text-sm mr-2" }, "Business Tags:"),
                React.createElement("div", { className: "flex flex-wrap gap-2" }, businessCategories.map((tag) => (React.createElement("span", { key: tag, className: "text-xs bg-gray-100 text-gray-800 rounded-full px-3 py-1" }, tag)))))),
            certifications && certifications.length > 0 && (React.createElement("div", null,
                React.createElement("span", { className: "font-semibold text-sm mr-2" }, "Certificates:"),
                React.createElement("div", { className: "flex flex-wrap gap-2" }, certifications.map((cert) => (React.createElement("span", { key: cert, className: "text-xs bg-gray-100 text-gray-800 rounded-full px-3 py-1" }, cert)))))))));
};
export default SupplierCard;
